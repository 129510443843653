<template>
  <v-container>
    <v-row v-if="$vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly">
      <v-col lg="6">
        <div v-if="homePage" class="d-flex flex-row justify-center mt-7">
          <v-btn
            fab
            small
            class="outlined"
            style="box-shadow: none !important;"
            :class="{ active: step >= 1, 'active-glow': step > 1 }"
            @click="setStep(1)"
          >
            <span
              class="nav-btn-text"
              :class="{
                'nav-btn-text-color': step >= 1,
                'active-nav-btn-text-color ': step > 1
              }"
              >1</span
            >
          </v-btn>
          <div class="line"></div>
          <v-btn
            fab
            small
            class="outlined"
            style="box-shadow: none !important;"
            :class="{ active: step >= 2, 'active-glow': step > 2 }"
            @click="
              (step === 1 && valid === true) || step === 3 ? setStep(2) : false
            "
          >
            <span
              class="nav-btn-text"
              :class="{
                'nav-btn-text-color': step >= 2,
                'active-nav-btn-text-color ': step > 2
              }"
              >2</span
            >
          </v-btn>
          <div class="line"></div>
          <v-btn
            fab
            small
            class="outlined"
            style="box-shadow: none !important;"
            :class="{ active: step === 3 }"
            @click="
              step === 2 && valid === true && activatedStep3 === true
                ? setStep(3)
                : false
            "
          >
            <span
              class="nav-btn-text"
              :class="{ 'nav-btn-text-color': step === 3 }"
              >3</span
            >
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="6" offset-sm="3">
        <transition name="fade" mode="out-in">
          <div v-if="step === 1" key="1">
            <h1 class="lets-get-started">
              Let’s get started
            </h1>
            <v-form ref="form" v-model="valid">
              <label class="community-label">Community Name</label>
              <v-text-field
                class="community-input"
                v-model="community.name"
                @keyup="communityControl"
                :rules="rules.nameRules"
                solo
                flat
                :append-icon="communityValidIcon"
              ></v-text-field>

              <label class="subdomain-label">Subdomain</label>
              <v-text-field
                class="subdomain-input"
                v-model="community.subdomain"
                :rules="rules.subdomainRules"
                suffix=".getocto.com"
                solo
                flat
                :append-icon="subdomainValidIcon"
              ></v-text-field>
            </v-form>
          </div>
          <div v-else-if="step === 2" key="2">
            <h1 class="create-your-account">
              Create Your Account
            </h1>
            <v-form ref="form" v-model="valid">
              <v-text-field
                v-model="community.account.firstname"
                :rules="rules.firstnameRules"
                class="step2-inputs"
                placeholder="Name*"
                outlined
                flat
              ></v-text-field>
              <v-text-field
                v-model="community.account.lastname"
                :rules="rules.lastnameRules"
                class="step2-inputs"
                placeholder="Last Name*"
                outlined
                flat
              ></v-text-field>
              <v-text-field
                v-model="community.account.email"
                :rules="rules.emailRules"
                class="step2-inputs"
                placeholder="E-mail*"
                outlined
                flat
              ></v-text-field>
              <vue-tel-input-vuetify
                ref="phoneInput"
                v-model="community.account.phone"
                :rules="rules.phoneRules"
                class="step2-phone"
                placeholder="Enter your mobile number*"
                :single-line="true"
                :outlined="true"
                :flat="true"
                defaultCountry="TR"
                autocomplete="on"
                :maxLen="10"
                :validCharactersOnly="true"
              ></vue-tel-input-vuetify>
              <v-textarea
                class="step2-textarea "
                label="Terms & Conditions"
                rows="5"
                readonly
                value='Please read these Terms of Use carefully before subscribing to the www.getocto.com domain website (hereinafter referred to as the "Website") and the Octo Community Management mobile application (hereinafter referred to as the "Application") carefully and If you do not approve of all the articles and provisions of the Terms of Use, do not complete the membership process.
                    • PARTIES AND SUBJECT
                    • These Terms of Use are valid for those who become a member of the Website and the Application as a member and visit the Website and the Application. Therefore, please read the terms written below carefully. If you do not accept these conditions, do not become a member of the Website and the Application and do not visit the Website and the Application.
                    • Owner of the website and the Application, "Sultan Selim Mah. Eski Buyukdere Cad. No:61 İc Kapi No:2 Kagithane/Istanbul” is the Ahtapot Bilişim Sistemleri Anonim Şirketi. Services offered through the Website and Application are provided by the Company.
                    • DEFINITIONS
                  Company: Ahtapot Bilişim Sistemleri Anonim Şirketi.
                  Website: It is the website with the domain name www.getocto.com (including all subdomains) that all rights belong to the Company.
                  Application: It is a mobile application named "Octo Community Management" or named by the user, all rights of which belong to the Company.
                  Platform: It refers to the mobile phone application running on Android and iOS operating systems or the websites accessed via the web.
                  Member: Persons who register on the Platform and whose membership is approved by the Platform Owners and who benefit from the Services offered on the Platform within the conditions specified in these Terms of Use.
                  User: Refers to the natural or legal person accessing the Platform.
                  Services: It refers to the applications put forward by the Platform Owners in order to enable the users to perform the work and transactions defined in these Terms of Use within the Platform.
                  Content: All kinds of information, files, videos, pictures, figures, prices, etc., which are published or accessible on the Platform, are visual, literary and auditory images.
                  Link: It is a link that enables access to another website, files, content or from another website to the Site, files and content through the Platform.
                  Visitor: Persons who visit the Website without being a member.
                  Member: Refers to the real person who create an account on the Website.
                    • GENERAL PROVISIONS
                    • The platform allows users to chat, communicate with each other, and make special announcements and promotions for the User or Member. In this framework, it is not possible for the Platform Owners or any third parties to control or control the content, information and images uploaded by the Users or the Members themselves on the Platform, including but not limited to these. The legal and penal responsibility for all transactions and actions performed by the Users or Members within the Platform belong to them individually, and in case of a contrary situation, any legal or penal request that may be directed to the Platform Owners shall be directed to the Users and/or Members themselves. will be revoked. 
                    • Users and Members provide direct or indirect benefits such as reproduction, processing, distribution, copying, sale of content uploaded to the Platform by other Users or Members, whether commercial or not, and/or copyright, intellectual property or may not engage in any unlawful action or activity that violates personal rights, constitutes a crime, or may result in an administrative, legal, or criminal liability for compensation.  
                    • The responsibility for the sale and marketing of the products and services promoted and advertised on the Platform belongs entirely to the addressee making the relevant advertisement, and the Platform Owners do not make any guarantees or commitments to the Users and Members in these and similar matters. Platform Owners cannot be held responsible for the products and services promoted and advertised.
                    • Platform Owners, their affiliates, institutions, and organizations they are affiliated with or cooperate with, Platform Owners employees, managers and consultants do not have any legal or criminal liability due to the illegality of the services provided and the Content published within the Platform. The commitment and control of the accuracy and legality of the comments made by the users, the information provided, and the content are entirely the responsibility of the Users or Members who perform these actions. Platform Owners are not obliged to check the security, accuracy and legality of the Content and do not make any commitments or guarantees in this regard.
                    • Due to the direct and indirect damages incurred or to be incurred by third parties due to all kinds of actions and activities on the Platform performed by the Users or Members in violation of the provisions of the Platform Terms of Use and the law; Platform Owners, their affiliates, managers, employees, affiliated institutions and organizations have no direct and/or indirect responsibility. Any legal or criminal claims that may be directed to Platform Owners due to the actions and activities of Users or Members through the Platform will be recourse to them.
                    • The ideas and thoughts declared, written and used by the Users or Members on the Platform are completely the personal opinions of the Parties and these issues are binding on the owner of the opinion. These views and opinions have no interest or connection with the Platform Owners, affiliates, managers, employees, affiliated institutions and organizations. The comments made by the User and Members, the information provided and the Content must be correct and in accordance with the law. Platform Owners are not obliged to investigate the accuracy of the comments, information and content transmitted to the Platform Owners by the Users or Members or uploaded, changed and made by them on the Platform; does not make any commitment or guarantee that the comments, information and content in question are safe, accurate and legal.
                    • Users or Members are obliged not to violate the legal rights of third parties while using any content or communication system of the Platform Owners. Platform Owners do not accept any legal and penal responsibility for such violations, and any legal and criminal liability that may arise belongs exclusively to the User or Member. Under no circumstances will the Platform be used for any action that is illegal, insulting, disrupting public safety, violating the constitutional regime and the state, violating personal or intellectual property rights, which may be the subject of a crime within the framework of the laws of the Republic of Turkey. Otherwise, all responsibilities arising from these and similar actions and the implementation of access restrictions that restrict the freedom of communication will belong to the User or Member, and all possible damages will be compensated at the first written request.
                    • Platform Owners reserve the right to change the Content on the Platform and the comments and information added by the User or Members at any time. Platform Owners can use this right without any notice and without giving any period of time. Users or Members must promptly implement the changes and/or corrections requested by the Platform Owners. Changes and/or correction requests requested by Platform Owners can be made by Platform Owners if deemed necessary.
                    • Platform Owners may link to websites and/or Content and/or files owned or operated by third parties that are not under the Platform Owner`s control over the Platform. These Links are provided for ease of reference only and do not make any commitments or guarantees for the purpose of supporting the relevant website or the operator, or that the website or the information it contains is correct and legal. Platform Owners, managers, consultants, employees, affiliated institutions and organizations have no responsibility for the websites, files and content accessed through the Links on the Platform, the services or products offered from the websites accessed through these Links, or their content. . The owners of the websites in question are entirely responsible for the Content and no demands can be made to the Platform Owners regarding this matter.
                    • Institutions and Organizations that advertise within the scope of producing income-generating models such as creating specific target audiences for Institutions and Organizations that advertise their own information such as Users and Members, such as occupation, income level and interests, making special advertisements for these target audiences and delivering them to these target audiences. consents to its use, processing and storage by In this context, the name, surname, address, contact information in the database, Personal data such as identity number will not be shared with third parties in any way. However, provided that the purposes specified in this article are fulfilled, information such as profession, income level and interests will be shared with the relevant institutions and organizations in order to create special audiences. Platform Owners can use, process, classify and store their personal data, such as their profession, income level and interests, as well as personal data transmitted to them by the User or Member through the Platform.
                    • Platform Owners provide information such as, but not limited to, the User`s or Member`s identity, address, e-mail address, telephone, IP address, which parts of the Platform he visited, domain type, browser type, date and time of visit. may use, process, make use of and transfer to third parties, especially the company that operates the Platform, within the scope of the agreements it has with its business partners and for purposes such as statistical evaluation and providing personalized services. In all cases and conditions, the name, surname, address, contact information and Identity Number of the Users or Members. Personal data such as identity number will never be processed, used or transferred to third parties for purposes other than the operation of the Platform. Limited to the scope and purposes set forth herein, the User or Members expressly consented to the processing, preservation and transfer of such personal data to third parties and institutions when necessary, within the scope of the Law No. 6698 on the Protection of Personal Data.
                    • Platform Owners are completely free to determine which of the Services and Content they make available within the Platform will be subject to a fee.
                    • Platform Owners do not undertake in any way to provide uninterrupted or specific service to Users or Members. It does not make any suggestions or advice to the User, and does not give any assurances to the User or Member regarding that the operation and administration of the Platform will be error-free and uninterrupted, malfunctions, defects and defects will be corrected, and that the Platform is free of harmful elements and viruses. and makes no guarantees. Platform Owners are also not responsible for security vulnerabilities that may arise from the network of service providers and other internet-related issues.
                    • The information in the Platform Owners is provided for the sole purpose of informing the User. However, the responsibility of any action to be taken by relying on the information published on the Platform belongs to the User or the Member himself.
                    • Any negative or positive damages that may arise from access to the Platform, direct or indirect use of the information and/or portal services on the Platform, regardless of the name, the members of the Board of Directors of the Platform Owners, the institutions and organizations to which the Platform Owners are affiliated, their affiliates, managers, employees, people who prepare this site and / or portal services do not have any legal or criminal responsibility.
                    • While the User or Member is using the Platform, the Turkish Penal Code, the Turkish Commercial Code, the Code of Obligations, the Law on Intellectual and Artistic Works, the Decree-Laws (Türk Ceza Kanunu, Türk Ticaret Kanunu, Borçlar Kanunu, Fikir ve Sanat Eserleri Kanunu) and legal regulations, the provisions of any relevant legislation currently in force and the legislation that will come into force in the future, the Platform`s agrees to comply with any notices to be published regarding All legal, penal and financial responsibilities that may arise due to notifications and illegal use belong to the User or Member.
                    • PROVISIONS ON INTELLECTUAL AND INDUSTRIAL PROPERTY RIGHTS
                    • Platform Users may not copy, process, modify, reproduce, directly or indirectly use or disclose the Content on the Platform, whether for commercial purposes or not.
                    • The titles and spots of the content published on the Platform can be used on Users or Members websites, forums by specifying the source, and related links can be given.
                    • All materials in the content of the Platform, including code and software, are protected in accordance with the legal legislation on Intellectual and Industrial Rights. In this framework, but not limited to those listed, duplication, processing, interference with the software and software related to the Platform, and those who act unlawfully by engaging in such transactions will have legal and penal responsibilities in accordance with the applicable legislation.
                    • All kinds of content and applications uploaded by Users or Members to the Platform and/or their processing and/or sound recordings and/or any arrangements and versions created and/or any form obtained from and/or linked to them All kinds of determinations will be determined by the Platform Owners, in all kinds of media/platforms/products/materials/organizations etc. (radio, TV, internet, cinema, direct marketing activities, press conferences, written and visual media, electronic or digital/digital environments such as wap, kiosk, mobile phone, telephone exchange and IVR music, telephone ring tones, etc.) It can be disseminated, processed, transmitted to the public by means of signal, sound and/or image transmission.
                    • CHANGES TO PLATFORM TERMS OF USE
                  Platform Owners may, at their sole discretion, change the Platform Terms of Use at any time by posting on the Platform. The amended provisions of these Platform Terms of Use will become valid on the date of their publication.
                    • APPLICABLE LAW AND DISPUTE RESOLUTION
                  Istanbul Central Courts and Enforcement Offices will be authorized for the implementation, interpretation of these Terms of Use and for the settlement of other disputes.
                    • ENFORCEMENT
                  These Terms of Use have come into force after being published on the Platform, and the use of the Platform means that the User has read and accepted all the articles in the Terms of Use.
'
              ></v-textarea>

              <div
                class="d-flex justify-md-center justify-lg-center justify-xl-center mt-5"
              >
                <div style="width:100%;">
                  <div class="d-flex" style="width:100%;">
                    <v-checkbox
                      class="step2-checkbox"
                      dense
                      :ripple="false"
                      label="I agree to the terms and conditions*"
                      v-model="community.agreement"
                      :rules="[v => !!v || 'You must agree to continue!']"
                      color="black"
                      on-icon="mdi-checkbox-marked-circle-outline"
                      off-icon="mdi-checkbox-blank-circle-outline"
                    >
                    </v-checkbox>
                  </div>
                  <div class="d-flex">
                    <v-checkbox
                      class="step2-checkbox"
                      dense
                      :ripple="false"
                      label="Send me the latest news via email"
                      v-model="community.newsletter"
                      color="black"
                      on-icon="mdi-checkbox-marked-circle-outline"
                      off-icon="mdi-checkbox-blank-circle-outline"
                    >
                    </v-checkbox>
                  </div>
                </div>
                <div
                  v-if="
                    $vuetify.breakpoint.mdOnly ||
                      $vuetify.breakpoint.lgOnly ||
                      $vuetify.breakpoint.xlOnly
                  "
                  class="pa-3"
                >
                  <!--<vue-recaptcha sitekey="Your key here"></vue-recaptcha>-->
                </div>
              </div>
              <div 
                class="d-flex mb-5"
              >
                <!--<vue-recaptcha sitekey="Your key here"> </vue-recaptcha>-->
                <vue-hcaptcha @verify="onVerify" sitekey="709b03ef-f70c-4590-9366-ed66f8dc1dc9"></vue-hcaptcha>
              </div>
            </v-form>
          </div>
          <div :class="['step3_container_max_width']" v-else-if="step === 3" key="3">
            <div
              :class="[
                'email_container',
                email_validation_container ? '' : 'passive'
              ]"
            >
             <div 
                class="pa-7 rounded-circle d-inline-block"
                style="background-color: #1bacc0 !important;
                      margin: 1vw 16vw;"   
              >
              <!-- <v-icon size="50" center style="object-fit: contain; color:white">mdi-email-check-outline</v-icon> -->
              <v-img src="../assets/email_check.svg" alt="email-check" style="object-fit: contain;"></v-img>
              </div>
              
              <h5 class="step3-text1">
                We have sent you a confirmation email, please check your inbox.
              </h5>
              <h1 class="text-center step3-text2">
                Enter your verification code
              </h1>
              <v-form ref="form" v-model="valid">
                <v-text-field
                  v-model="community.code"
                  @keyup="verificationControl"
                  :rules="rules.codeRules"
                  class="step3-inputs input-color-success"
                  placeholder="Verification Code"
                  maxlength="6"
                  outlined
                  flat
                  :success="setColorSuccessVerificationEmail"
                  :error="setColorErrorVerificationEmail"
                  :append-icon="verificationCodeIcon"
                  :disabled="disabledVerificationEmail"
                ></v-text-field>
              </v-form>
            </div>
            <div
              :class="[
                'phone_container',
                phone_validation_container ? '' : 'passive'
              ]"
            >
              <div 
                class="pa-6 rounded-circle d-inline-block"
                style="background-color: #1bacc0 !important;
                      margin: 1vw 16vw;"   
              >
              <!-- <v-icon size="40" center style="object-fit: contain; color:white">mdi-email-check-outline</v-icon> -->
              <v-img src="../assets/telephone_check.svg" alt="phone-check" style="object-fit: contain;"></v-img>
              </div>
              <h5 class="step3-text1">
                We have sent you a confirmation sms to ({{
                  community.account.phone
                }}), please check your mobile device.
              </h5>
              <h1 class="text-center step3-text2">
                Enter your verification code
              </h1>
              <v-form ref="form" v-model="validPhoneCode">
                <v-text-field
                  v-model="community.account.phoneCode"
                  @keyup="verificationControlPhoneCode"
                  :rules="rules.codeRules"
                  class="step3-inputs success-text"
                  placeholder="Phone Verification Code"
                  maxlength="6"
                  outlined
                  flat
                  :success="setColorSuccessVerificationPhone"
                  :error="setColorErrorVerificationPhone"
                  :append-icon="verificationCodeIconPhoneCode"
                  :disabled="disabledVerificationPhone"
                ></v-text-field>
              </v-form>
              
            </div>
          </div>
        </transition>
        <v-btn
          :class="
            step == 1
              ? 'next-button'
              : 'next-button-step3' || valid == true
              ? 'next-button-step3'
              : 'next-button'
          "
          :disabled="step == 3 ? !(valid && validPhoneCode) : !valid"
          @click="next"
        >
          <transition name="fade" mode="out-in">
            <div
              v-if="step !== 3"
              key="1"
              :class="valid == true ? 'next-text-white' : 'next-text'"
            >
              Next <v-icon class="button-arrow-icon">mdi-arrow-right</v-icon>
            </div>
            <div
              v-else
              key="2"
              :class="
                valid == true && validPhoneCode == true
                  ? 'start-text-white'
                  : 'startcreateCommunity-text'
              "
            >
              Let’s Start
            </div>
          </transition>
        </v-btn>
        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import VueHcaptcha from '@hcaptcha/vue-hcaptcha'
import AhtapotJS from '@ahtapotapp/ahtapot-js-sdk'

export default {
  components: { VueHcaptcha },
  data: () => ({
    ph: '',
    captcha_verification: false,
    phone_validation_container: false,
    email_validation_container: true,
    communityValidIcon: "",
    subdomainValidIcon: "",
    verificationCodeIcon: "",
    verificationCodeIconPhoneCode: "",
    disabledVerificationEmail: false,
    disabledVerificationPhone: false,
    setColorSuccessVerificationEmail: false,
    setColorErrorVerificationEmail: false,
    setColorErrorVerificationPhone: false,
    setColorSuccessVerificationPhone: false,
    community: {
      name: "",
      subdomain: "",
      account: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        phoneCode: ""
      },
      hcaptcha: '',
      agreement: false,
      newsletter: true,
      code: ""
    },
    rules: {
      nameRules: [
        v => !!v || "Community name is required",
        v =>
          (v && v.length >= 3) ||
          "Community name must be more than 3 characters"
      ],
      subdomainRules: [
        v => !!v || "Subdomain is required",
        v =>
          /^([a-zA-Z0-9][a-zA-Z0-9-_]*\.)*[a-zA-Z0-9]*[a-zA-Z0-9-_]*[[a-zA-Z0-9]+$/.test(
            v
          ) || "Subdomain must be valid"
      ],
      firstnameRules: [
        v => !!v || "First name is required",
        v => (v && v.length >= 3) || "First name must be more than 3 characters"
      ],
      lastnameRules: [
        v => !!v || "Last name is required",
        v => (v && v.length >= 2) || "Last name must be more than 2 characters"
      ],
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
      phoneRules: [
        v => !!v || "Phone number is required",
        v =>
          (v && v.match(/\d/g) && v.match(/\d/g).length >= 10) ||
          "Phone number must be valid"
      ],
      codeRules: [
        v => !!v || "Code is required",
        v => (v && v.length === 6) || "Code must be valid"
      ]
    }
  }),
  mounted() {},
  watch: {
    communityNameValid: {
      handler: function(newV, oldV) {
        if (newV == true) {
          this.communityValidIcon = "mdi-check-circle-outline";
        } else {
          this.communityValidIcon = "mdi-alert-circle-outline";
        }
      }
    },
    subdomainValid: {
      handler: function(newV, oldV) {
        if (newV == true) {
          this.subdomainValidIcon = "mdi-check-circle-outline";
        } else {
          this.subdomainValidIcon = "mdi-alert-circle-outline";
        }
      }
    }
  },
  computed: {
    ...mapState({
      step: state => state.step,
      verificationCode: state => state.verificationCode,
      verificationCodePhoneCode: state => state.verificationCodePhoneCode
    }),
    valid: {
      get() {
        return this.$store.state.valid;
      },
      set(value) {
        this.$store.commit("setValid", value);
      }
    },
    validPhoneCode: {
      get() {
        return this.$store.state.validPhoneCode;
      },
      set(value) {
        this.$store.commit("setValidPhoneCode", value);
      }
    },
    communityNameValid: {
      get() {
        return this.$store.state.communityNameValid;
      }
    },
    subdomainValid: {
      get() {
        return this.$store.state.subdomainValid;
      }
    },
    homePage() {
      return this.$route.path == "/";
    }
  },
  methods: {
    onVerify(token, eKey) {
      this.captcha_verification = true
      this.community.hcaptcha = token
    },
    ...mapActions(["incrementStep", "createCommunity", "setStep"]),
    next() {
      const self = this;
      if (this.step === 2) {
        if(this.captcha_verification != true){
          self.$notify({
              text: 'Please complete the CAPTCHA :)',
              type: 'error'
          })
          return
        }

        // request to company create
        //this.community.account.phone = "+" + this.$refs.phoneInput.countryCode.dialCode + this.community.account.phone
        this.community.account.dialCode = this.$refs.phoneInput.countryCode.dialCode;
        this.createCommunity(this.community).then(function() {
          self.$emit("step3-activated", true);
          self.incrementStep();
        });
      }
      if (this.step !== 3 && this.step !== 2) {
        this.incrementStep();
      }
      if(this.step == 3 && this.valid == true && this.validPhoneCode == true){
        window.open('https://'+ self.community.subdomain +'.getocto.com/#/session/login?ph=' + self.ph, '_self');
      }
    },
    communityControl(e) {
      if (e.target.value.length >= 2) {
        this.community.subdomain = e.target.value
          .toLowerCase()
          .split(" ")
          .join("-")
          .replace(/Ğ/gim, "g")
          .replace(/Ü/gim, "u")
          .replace(/Ş/gim, "s")
          .replace(/I/gim, "i")
          .replace(/İ/gim, "i")
          .replace(/Ö/gim, "o")
          .replace(/Ç/gim, "c")
          .replace(/ğ/gim, "g")
          .replace(/ü/gim, "u")
          .replace(/ş/gim, "s")
          .replace(/ı/gim, "i")
          .replace(/ö/gim, "o")
          .replace(/ç/gim, "c");

        let community = {
          communityName: this.community.name,
          subdomain: this.community.subdomain
        };

        this.$store.dispatch("checkCommunity", community);
      } else {
        this.community.subdomain = e.target.value
          .toLowerCase()
          .split(" ")
          .join("-");
      }
    },
    verificationControl(e) {
      const self = this;
      this.valid = false;
      if (e.target.value.length == 6) {
        self.verificationCodeIcon = "fas fa-circle-notch fa-spin";

        self.community.code = e.target.value;
        self.$store
          .dispatch("checkVerificationCode", self.community.code)
          .then(function() {
            if (self.verificationCode == false) {
              self.verificationCodeIcon = "mdi-check-circle-outline";
              self.phone_validation_container = true;
              self.email_validation_container = false;
              self.disabledVerificationEmail = true;
              self.setColorSuccessVerificationEmail = true;
            }
          })
          .catch(function(err) {
            if (self.verificationCode == true) {
              self.verificationCodeIcon = "mdi-alert-circle-outline";
              self.setColorErrorVerificationEmail = true;
              self.valid = false;
            }
          });
      } else {
        this.valid = false;
      }
    },
    verificationControlPhoneCode(e) {
      const self = this;
      this.valid = false;
      if (e.target.value.length == 6) {
        self.verificationCodeIconPhoneCode = "fas fa-circle-notch fa-spin";

        self.community.account.phoneCode = e.target.value;
        self.$store
          .dispatch(
            "checkVerificationCodePhoneCode",
            self.community.account.phoneCode
          )
          .then(function(resp) {
            if (self.verificationCodePhoneCode == false) {
              self.verificationCodeIconPhoneCode = "mdi-check-circle-outline";
              self.phone_validation_container = true;
              self.email_validation_container = false;
              self.setColorSuccessVerificationPhone = true;
              self.disabledVerificationPhone = true;
              self.validPhoneCode = true;
              self.valid = true
              self.$emit('allStepCorrectDisableButton', true)
              
              console.log("finished:", resp)

              window.$service = new AhtapotJS({
                companyId: self.community.subdomain,
                domain: self.community.subdomain
              })

              var auth = window.$service.authentication();
              self.ph = resp.data.passphrase.passphrase;
              auth.token(resp.data.passphrase.passphrase).then(function (a) {
                let token = a.data.token.jwt;

                let user = window.$getStarted.user()
                user.register (self.community.account.email).then (function (a) {
                  console.log('user registered')
                  
                  let data = {
                    offerId: 5071,
                    isNew: true,
                    subscriber: {
                      email: self.community.account.email,
                      name: self.community.account.firstname,
                      surname: self.community.account.lastname
                    }
                  }
                  var subscription = window.$getStarted.subscription();
                  subscription.start(data).then(function(a){
                    console.log("subscription response: ", a)
                  }).catch(function(b){
                    console.log("subscription response error: ", b)
                  })
                }).catch (function (b) {
                  console.log('user register process failed: ', b)
                })
              }).catch(function (err) {
                console.error('Error', err);
              })
            }
          })
          .catch(function(err) {
            if (self.verificationCodePhoneCode == true) {
              self.verificationCodeIconPhoneCode = "mdi-alert-circle-outline";
              self.setColorErrorVerificationPhone = true
              self.validPhoneCode = false
            }
          });
      } else {
        this.validPhoneCode = false;
      }
    }
  }
};
</script>

<style scoped>

.input-color >>> .v-application .primary--text {
    color: black !important;
    caret-color: black !important;
}

div.phone_container,
div.email_container {
  position: relative;
}
div.phone_container.passive:after,
div.email_container.passive:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(235, 235, 235, 0.8);
  top: 0;
  left: 0;
  border-radius: 10px;
}
/*mobile step*/

.v-btn.outlined {
  border: 2px solid rgba(176, 189, 196, 0.6);
  width: 40px !important;
  height: 40px !important;
}
.v-btn::before {
  background-color: transparent !important;
}
.v-btn:hover::before {
  background-color: #f7f7f7;
}

.nav-btn-text {
  font-family: GoogleSans-Regular;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #1bacc0;
}
.nav-btn-text-color {
  color: #1bacc0;
}
.active-nav-btn-text-color {
  color: #ffffff;
}

.v-text-field .v-label--active {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  color: #243137;
}

.line {
  display: inline-block;
  width: 48px;
  height: 0px;
  margin: 20px 0 0;
  border: solid 2px rgba(176, 189, 196, 0.6);
  opacity: 0.5;
}

.active {
  background-color: transparent !important;
  border: solid 2px #1bacc0 !important;
}
.active-glow {
  background-color: #1bacc0 !important;
  border: solid 2px #1bacc0 !important;
}

/* Home component */

.step2-phone >>> .country-code .v-input__slot {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-right: 0px !important;
}
.step2-phone >>> .v-text-field--placeholder .v-input__slot {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-left: 0px !important;
}

.v-btn {
  text-transform: none !important;
}
/*
.v-input--selection-controls {
  margin-top: -16px;
  padding-top: -4px;
}
*/

.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}

.lets-get-started {
  height: 71px;
  margin-top: 100px;
  margin-bottom: 40px;
  margin-left: 10px;
  font-family: GoogleSans-Regular;
  font-size: 56px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: center;
  color: #243137;
}

.community-label {
  width: 131px;
  height: 20px;
  font-size: 16px;
  font-family: GoogleSans-Regular;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #90a4ad;
}
.community-input >>> .v-input__slot {
  width: 600px;
  height: 80px;
  font-size: 24px !important;
  border-radius: 8px;
  border: solid 1px rgba(144, 164, 173, 0.4);
  background-color: #ffffff;
}
.community-input >>> .mdi-alert-circle-outline::before {
  font-size: 28px;
  color: red;
}
.community-input >>> .mdi-check-circle-outline::before {
  font-size: 28px;
  color: green;
}

.subdomain-label {
  width: 81px;
  height: 20px;
  margin: 28px 1px 12px 0;
  font-family: GoogleSans-Regular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #90a4ad;
}
.subdomain-input >>> .v-input__slot {
  width: 600px;
  height: 80px;
  font-size: 24px !important;
  border-radius: 8px;
  border: solid 1px rgba(144, 164, 173, 0.4);
  background-color: #ffffff !important;
}
.subdomain-input >>> .v-text-field__suffix {
  margin-right: 20px;
  line-height: 1.25;
  color: #90a4ad;
}
.subdomain-input >>> .mdi-alert-circle-outline::before {
  font-size: 28px;
  color: red;
}
.subdomain-input >>> .mdi-check-circle-outline::before {
  font-size: 28px;
  color: green;
}
.subdomain-input >>> .v-text-field__suffix {
  font-family: GoogleSans-Regular;
}

.create-your-account {
  width: 483px;
  height: 66px;
  margin: 24px 663px 14px 55px;
  font-family: GoogleSans-Regular;
  font-size: 52px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: center;
  color: #243137;
}
.step2-inputs >>> .v-input__slot {
  width: 600px;
  height: 60px;
  margin: 6px 0 0px;
  font-family: GoogleSans-Regular !important;
  font-size: 20px !important;
  padding: 18px 28px 17px 28px !important;
  border-radius: 8px;
  border: solid 1px rgba(144, 164, 173, 0.4);
  background-color: #ffffff !important;
}
.v-text-field--outlined >>> fieldset {
  border-color: rgba(0, 0, 0, 0);
}

.step2-phone >>> .v-input__slot {
  height: 60px;
  margin: 6px 0 0px;
  font-family: GoogleSans-Regular !important;
  font-size: 20px !important;
  border-radius: 8px;
  background-color: #ffffff !important;
  border: solid 1px rgba(144, 164, 173, 0.4);
}
.step2-phone >>> fieldset {
  border-color: rgba(0, 0, 0, 0);
}

.step2-textarea {
  width: 600px !important;
  height: auto;
  font-family: GoogleSans-Regular;
  font-size: 20px;
  line-height: 1.5rem;
  padding: 28px 10px 28px 28px !important;
  max-width: 120% !important;
  border-radius: 8px;
  border: solid 1px rgba(144, 164, 173, 0.4);
  background-color: #ffffff;
  user-modify: read-only;
}

.step2-textarea >>> .v-input__slot {
  margin-bottom: 0px !important;
}

.step2-textarea >>> label {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #243137;
  max-width: inherit !important;
  transform: translateY(0px) scale(1) !important;
}

.step2-textarea >>> textarea {
  margin-top: 44px;
  padding-right: 12px;
  border: none !important;
  box-shadow: 0px !important;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  min-height: 135px !important;
}

.step2-textarea >>> textarea::-webkit-scrollbar {
  width: 6px;
}

.step2-textarea >>> textarea::-webkit-scrollbar-track {
  border-radius: 8px;
}

.step2-textarea >>> textarea::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgba(176, 189, 196, 0.6);
}

.step2-textarea >>> .v-input__slot::before {
  border: none !important;
}

.step2-checkbox-text {
  font-family: GoogleSans-Regular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
}

.step2-checkbox >>> label {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
}

/* .step2-checkbox >>> .v-icon.v-icon--dense {

} */
.step2-checkbox
  >>> .v-input--selection-controls.v-input--dense
  .v-input--selection-controls__ripple {
  width: 0px;
  height: 0px;
}
.step2-checkbox >>> .theme--light.v-label {
  color: #243137 !important;
}
.v-application .pa-3 {
  padding: 0px !important;
  margin-left: 10px;
}

.step3-text1 {
  font-family: GoogleSans-Regular;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}
.step3-text2 {
  font-family: GoogleSans-Regular;
  font-size: 35px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.26;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  margin: 20px auto;
}
.step3-inputs >>> .v-input__slot {
  width: 100%;
  height: 80px;
  margin:auto !important;
  font-family: GoogleSans-Regular !important;
  font-size: 24px !important;
  /*padding: 18px 250px 25px 28px !important; ?*/
  border-radius: 8px;
  border: solid 1px rgba(144, 164, 173, 0.4);
  background-color: #ffffff !important;
}
.step3-inputs >>> .v-text-field__slot {
  display: block !important;
  text-align: center;
  padding-top: 21px;
  padding-left: 30px;
}
.step3-inputs >>> input {
  width: 100%;
  text-align: center;
  font-size: 40px;
  line-height: 3.28;
  letter-spacing: 40px;
  margin: auto;
}
.step3-inputs >>> input::placeholder {
  text-align: center !important;
  font-size: 24px;
  letter-spacing: 1px !important;
}

.step3-inputs >>> .v-input__append-inner {
  margin-top: 27px;
}

.next-button {
  width: 600px;
  height: 80px !important;
  margin: 0 0 0;
  padding: 25px 253px 25px 254px;
  border-radius: 8px;
  background-color: #1bacc0 !important;
}
.next-button-step2 {
  width: 600px;
  height: 80px !important;
  margin: 24px 0 0;
  padding: 25px 253px 25px 254px;
  border-radius: 8px;
  background-color: #1bacc0 !important;
}
.next-button-step3 {
  width: 100% !important;
  height: 80px !important;
  margin: 24px 0 0;
  padding: 25px 253px 25px 254px;
  border-radius: 8px;
  background-color: #1bacc0 !important;
}


.step3_container_max_width{
  position: relative;
  width: 700px;
}

.email_container {
    width: 100%;
    margin: auto;
    left: -3.5vw;
    padding: 2vw;
    background: #fff;
    border-radius: 10px;
    transition: all 0.5s;
}
.phone_container{
    width: 100%;
    margin: auto;
    left: -3.5vw;
    padding: 2vw;
    background: #fff;
    border-radius: 10px;
    transition: all 0.5s;
}


.v-btn {
  text-transform: none !important;
}
.button-arrow-icon {
  font-size: 34px;
}
.next-text {
  width: 53px;
  height: 30px;
  margin: 0 16px 0 0;
  font-family: GoogleSans-Medium !important;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #77909b;
}
.next-text-white {
  width: 53px;
  height: 30px;
  margin: 0 16px 0 0;
  font-family: GoogleSans-Medium !important;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}
.start-text {
  width: 53px;
  height: 30px;
  margin: 0 85px 0 0;
  font-family: GoogleSans-Medium !important;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #77909b;
}
.start-text-white {
  width: 53px;
  height: 30px;
  margin: 0 85px 0 0;
  font-family: GoogleSans-Medium !important;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* media query */
.step2-phone {
  width: 600px;
  margin: auto;
}
/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1920px){
  .phone_container.passive {
    position: fixed !important;
    top: 164px;
    left: 71%;
    max-width: 700px;
    transition: all 0.5s;
}

.email_container.passive {
    position: fixed !important;
    left: -7vw;
    max-width: 700px;
    transition: all 0.5s;
}
}

@media (min-width: 1680px) and (max-width: 1919px){
  .phone_container.passive {
    position: fixed !important;
    top: 164px;
    left: 74%;
    max-width: 700px;
    transition: all 0.5s;
}

.email_container.passive {
    position: fixed !important;
    left: -15vw;
    max-width: 700px;
    transition: all 0.5s;
}
}

@media (min-width: 1440px) and (max-width: 1679px){
  .phone_container.passive {
    position: fixed !important;
    top: 164px;
    left: 78%;
    max-width: 700px;
    transition: all 0.5s;
}

.email_container.passive {
    position: fixed !important;
    left: -25vw;
    max-width: 700px;
    transition: all 0.5s;
}
}

@media (min-width: 1366px) and (max-width: 1439px){
  .phone_container.passive {
    position: fixed !important;
    top: 164px;
    left: 80%;
    max-width: 700px;
    transition: all 0.5s;
}

.email_container.passive {
    position: fixed !important;
    left: -28vw;
    max-width: 700px;
    transition: all 0.5s;
}
}

@media (min-width: 1280px) and (max-width: 1365px){
  .phone_container.passive {
    position: fixed !important;
    top: 164px;
    left: 83%;
    max-width: 700px;
    transition: all 0.5s;
}

.email_container.passive {
    position: fixed !important;
    left: -34vw;
    max-width: 700px;
    transition: all 0.5s;
}
}

@media (max-width: 1279px){

.phone_container {
    width: 100%;
    margin: auto;
    left: 2vw;
    padding: 4vw;
    background: #fff;
    border-radius: 10px;
    transition: all 0.5s;
}

  .phone_container.passive {
    position: fixed !important;
    top: 164px;
    left: 83%;
    max-width: 700px;
    transition: all 0.5s;
}

.email_container.passive {
    position: fixed !important;
    left: -34vw;
    max-width: 700px;
    transition: all 0.5s;
}
}

@media (max-width: 576px){
  .step3_container_max_width {
    position: relative;
    width: 520px;
  }
}

@media (max-width: 425px){
  .step3_container_max_width {
    position: relative;
    width: 390px;
  }
  .phone_container {
    position: fixed !important;
    top: 164px;
    left: 0%;
    max-width: 700px;
    transition: all 0.5s;
  }
}


@media (min-width: 1025px) and (max-width: 1280px) {
  /*step 1 media query */

  .step2-phone {
    width: 100%;
    margin: auto;
  }

  .v-text-field.v-text-field--enclosed {
    flex: 5 !important;
  }

  .lets-get-started {
    font-size: 48px;
    margin-top: 20px !important;
    margin-left: 2% !important;
  }
  .community-label {
    margin-left: 0% !important;
  }
  .community-input >>> .v-input__slot {
    width: 100% !important;
    margin: auto;
  }
  .community-input >>> .v-messages__message {
    margin-left: 0% !important;
  }
  .subdomain-label {
    margin-left: 0% !important;
  }
  .subdomain-input >>> .v-input__slot {
    width: 100% !important;
    margin-left: 0% !important;
  }
  .subdomain-input >>> .v-messages__message {
    margin-left: 0% !important;
  }
  .next-button {
    width: 100% !important;
    margin-left: 0% !important;
  }

  .vue-tel-input-vuetify .country-code {
    width: inherit !important;
    flex: 1;
  }

  /* step2 media query */

  .create-your-account {
    width: 100% !important;
    font-size: 48px;
    margin: 10px !important;
    margin-top: 20px;
  }

  .step2-inputs >>> .v-input__slot {
    width: 100% !important;
    margin-left: 0% !important;
  }
  .step2-inputs >>> .v-messages__message {
    margin-left: 0% !important;
  }
  .step2-phone >>> .v-input__slot {
    width: 100% !important;
    margin-left: 0% !important;
  }
  .step2-phone >>> .v-messages__message {
    margin-left: 0% !important;
  }
  .step2-textarea {
    width: 100% !important;
    margin-left: 0% !important;
  }
  .step2-checkbox-text {
    font-size: 16px !important;
  }
  .step2-checkbox >>> .v-icon.v-icon--dense {
    width: 100% !important;
    font-size: 18px !important;
  }
  .step2-checkbox >>> .v-messages__message {
    margin-top: 5px !important;
  }
  .next-button-step2 {
    width: 100% !important;
    margin-left: 0% !important;
  }

  /*step 3 media query */

  .step3-text1 {
    width: 100%;
    margin: 40px 0px 80px 0px;
    font-family: GoogleSans-Regular;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #243137;
  }
  .step3-text2 {
    width: 100%;
    margin: 50px 0px 20px 0px;
    font-family: GoogleSans-Regular;
    font-size: 48px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #243137;
  }
  .step3-inputs >>> .v-input__slot {
    width: 100% !important;
    margin-left: 0% !important;
  }
  .step3-inputs >>> .v-messages__message {
    margin-left: 0% !important;
  }
  .next-button-step2 {
    width: 100% !important;
    margin-left: 0% !important;
  }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .v-btn.outlined {
    border: 2px solid rgba(176, 189, 196, 0.6);
    width: 45px !important;
    height: 45px !important;
  }

  /*step 1 media query */

  .v-text-field.v-text-field--enclosed {
    flex: 5;
  }

  .step2-phone {
    width: 100% !important;
    margin: auto;
  }

  .lets-get-started {
    font-size: 48px;
    margin-top: 20px !important;
  }
  .community-label {
    margin-left: 0% !important;
  }
  .community-input >>> .v-input__slot {
    width: 100% !important;
    margin-left: 0% !important;
  }
  .community-input >>> .v-messages__message {
    margin: auto;
  }
  .subdomain-label {
    margin-left: 0% !important;
  }
  .subdomain-input >>> .v-input__slot {
    width: 100% !important;
    margin-left: 0% !important;
  }
  .subdomain-input >>> .v-messages__message {
    margin: auto;
  }
  .next-button {
    width: 100% !important;
    margin-left: 0% !important;
  }

  /*step 2 media query */

  .create-your-account {
    width: 100% !important;
    font-size: 48px;
    margin: 10px !important;
    margin-top: 20px;
  }

  .step2-inputs >>> .v-input__slot {
    width: 100% !important;
    margin: auto;
  }
  .step2-inputs >>> .v-messages__message {
    margin: auto;
  }
  .step2-phone >>> .v-input__slot {
    width: 100% !important;
    margin-left: 0% !important;
  }
  .step2-phone >>> .v-messages__message {
    margin: auto;
  }
  .step2-textarea {
    width: 100% !important;
    margin: auto;
  }
  .step2-checkbox >>> .v-label {
    width: 480px !important;
    height: 20px !important;
    margin: 2px 2px 0px 0px;
    font-family: GoogleSans-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
  }
  .step2-checkbox >>> .v-icon.v-icon--dense {
    width: 100% !important;
    font-size: 24px !important;
    margin-top: 0px !important;
  }
  /* .step2-checkbox >>> .v-input__control {
    
  } */
  .next-button-step2 {
    width: 100% !important;
    margin: auto;
  }

  /*step 3 media query */

  .step3-text1 {
    width: 100%;
    margin: 40px 0px 20px 0px;
    font-family: GoogleSans-Regular;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #243137;
  }
  .step3-text2 {
    width: 100%;
    margin: 50px 0px 20px 0px;
    font-family: GoogleSans-Regular;
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #243137;
  }
  .step3-inputs >>> .v-input__slot {
    width: 100% !important;
    margin: auto;
    margin: 0px 0px;
  }
  .step3-inputs >>> .v-messages__message {
    margin: auto;
  }
  .next-button-step2 {
    width: 100% !important;
    margin: auto;
  }
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .v-btn.outlined {
    border: 2px solid rgba(176, 189, 196, 0.6);
    width: 45px !important;
    height: 45px !important;
  }

  /*step 1 media query */

  .lets-get-started {
    font-size: 48px;
    margin-top: 20px !important;
  }
  .community-label {
    margin: auto;
  }
  .community-input >>> .v-input__slot {
    width: 100% !important;
    margin: auto;
  }
  .subdomain-label {
    margin: auto;
  }
  .subdomain-input >>> .v-input__slot {
    width: 100% !important;
    margin: auto;
  }
  .next-button {
    width: 100% !important;
    margin: auto;
  }

  /*step 2 media query */

  .create-your-account {
    width: 100% !important;
    font-size: 48px;
    margin: 10px !important;
    margin-top: 20px;
  }

  .step2-inputs >>> .v-input__slot {
    width: 100% !important;
    margin: auto;
  }
  .step2-phone >>> .v-input__slot {
    width: 100% !important;
    margin: auto;
  }
  .step2-textarea {
    width: 100% !important;
    margin: auto;
  }
  .step2-checkbox-text {
    font-size: 16px !important;
  }
  .step2-checkbox >>> .v-icon.v-icon--dense {
    width: 100% !important;
    font-size: 18px !important;
  }
  .next-button-step2 {
    width: 100% !important;
    margin: auto;
  }
  .step2-phone >>> .v-input__slot {
    width: 100% !important;
    margin-left: 0% !important;
  }
}

@media (min-width: 980px) and (max-width: 1023px) {
  .step2-phone >>> .v-input__slot {
    width: 100% !important;
    margin-left: 0% !important;
  }
  .step2-phone >>> .v-messages__message {
    margin: auto;
  }
}

@media (min-width: 980px) and (max-width: 1023px) {
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  /*step 1 media query */

  .step3-inputs >>> input {
    margin-left: 50px;
    margin-top: -1px;
    width: 250px;
    font-size: 40px;
    line-height: 3.28;
    letter-spacing: 40px;
  }

  .step2-phone {
    width: 100% !important;
    margin: auto;
  }

  .lets-get-started {
    font-size: 36px;
    margin-top: 20px !important;
  }
  /* .community-label {
    
  } */
  .community-input >>> .v-input__slot {
    width: 100% !important;
    margin: 10px !important;
  }
  /* .subdomain-label {
    
  } */
  .subdomain-input >>> .v-input__slot {
    width: 100% !important;
    margin: 10px !important;
  }
  .next-button {
    width: 100% !important;
    margin: 10px !important;
  }

  /*step 2 media query */

  .create-your-account {
    width: 100% !important;
    font-size: 36px;
    margin-left: 1% !important;
    margin-top: 20px;
  }

  .step2-inputs >>> .v-input__slot {
    width: 100% !important;
    font-size: 16px !important;
    margin-left: 0%;
  }
  .step2-inputs >>> .v-messages__message {
    margin-left: 0% !important;
  }
  .step2-phone >>> .v-input__slot {
    width: 100% !important;
    margin-left: 0%;
    font-size: 16px !important;
  }
  .step2-textarea {
    width: 100% !important;
    font-size: 16px !important;
    margin-left: 0%;
  }
  .step2-checkbox-text {
    font-size: 16px !important;
  }
  .step2-checkbox >>> .v-icon.v-icon--dense {
    width: 100% !important;
    font-size: 18px !important;
  }
  .next-button-step2 {
    width: 100% !important;
    height: 60px !important;
    margin-left: 0%;
  }

  /*step 3 media query */

  .step3-text1 {
    width: 100%;
    margin: 40px 0px 40px 0px;
    font-family: GoogleSans-Regular;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #243137;
  }
  .step3-text2 {
    width: 100%;
    margin: 50px 0px 20px 0px;
    font-family: GoogleSans-Regular;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #243137;
  }
  .step3-inputs >>> .v-input__slot {
    width: 100% !important;
    margin-left: 0% !important;
    height: 60px !important;
    margin: 0px 0px;
  }
  .step3-inputs >>> .v-messages__message {
    margin-left: 0% !important;
  }
  .step3-inputs >>> .v-text-field__slot {
    margin-left: -5%;
    padding-top: 2%;
    padding-left: 0px;
  }
  .next-button-step2 {
    width: 100% !important;
    margin-left: 0% !important;
    height: 60px !important;
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media screen and (max-width: 480px) {
  .col-lg-6 {
    padding-right: 5.4%;
    padding-left: 5.4%;
  }

  /* step 1 media query */

  .footer.v-footer.v-sheet.theme--light.v-footer--padless {
    max-width: 320px !important;
    overflow: hidden;
  }

  .lets-get-started {
    margin: 16px 0px 40px 0px;
    font-family: GoogleSans-Regular;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #243137;
  }
  .community-label {
    font-size: 14px !important;
  }
  .community-input >>> .v-input__slot {
    width: 100% !important;
    height: 60px !important;
    font-size: 16px !important;
  }
  .subdomain-label {
    font-size: 14px !important;
  }
  .subdomain-input >>> .v-input__slot {
    width: 100% !important;
    height: 60px !important;
    font-size: 16px !important;
  }
  .next-button {
    width: 100% !important;
    height: 60px !important;
    margin-right: 5px !important;
    margin-left: 5px !important;
    margin-top: 18px !important;
    margin-bottom: 50px !important;
  }
  .button-arrow-icon {
    font-size: 20px !important;
    margin-bottom: 4%;
  }
  .next-text {
    width: 53px;
    height: 30px;
    margin: 0 16px 0 0;
    font-size: 18px;
    line-height: 1.7;
  }
  .next-text-white {
    width: 53px;
    height: 30px;
    margin: 0 16px 0 0;
    font-size: 18px;
    line-height: 1.7;
  }

  .step2-phone {
    width: 100% !important;
    margin: auto;
  }

  /*step 2 media query */

  .create-your-account {
    width: 100%;
    height: 36px !important;
    margin: 16px 0px 15px 0px;
    font-family: GoogleSans-Regular;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #243137;
  }

  .step2-inputs >>> .v-input__slot {
    width: 100% !important;
    font-size: 16px !important;
  }
  .step2-phone >>> .v-input__slot {
    width: 100% !important;
    margin: 0px !important;
    font-size: 16px !important;
  }
  .step2-textarea {
    width: 100% !important;
    padding: 28px 12px 20px 20px !important;
    font-size: 14px !important;
  }
  .step2-textarea >>> .v-label {
    font-size: 20px;
    line-height: 25px;
    min-height: 8px;
    color: #243137 !important;
    font-weight: bold !important;
  }
  .step2-checkbox >>> .v-label {
    width: 259px !important;
    height: 20px !important;
    margin: 2px 2px 0px 0px;
    font-family: GoogleSans-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
  }
  .step2-checkbox >>> .v-icon.v-icon--dense {
    width: 100% !important;
    font-size: 24px !important;
    margin-top: 0px !important;
  }
  /* .step2-checkbox >>> .v-input__control {
    
  } */
  .next-button-step2 {
    width: 100% !important;
    height: 60px !important;
    margin: 10px 0px 44px !important;
  }

  /*step 3 media query */

  .step3-text1 {
    width: 100%;
    margin: 40px 0px 40px 0px;
    font-family: GoogleSans-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #243137;
  }
  .step3-text2 {
    width: 100%;
    max-height: 40px !important;
    margin: 20px 0px 20px 0px;
    font-family: GoogleSans-Regular;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #243137;
  }
  .step3-inputs >>> .v-input__slot {
    width: 100% !important;
    height: 60px !important;
  }
  .step3-inputs >>> input {
    margin-left: 0%;
    margin-top: 10px;
    width: 250px;
    font-size: 18px;
  }
  .step3-inputs >>> .v-text-field__slot {
    padding-top: 5px;
    padding-left: 0px !important;
  }
  .step3-inputs >>> input::placeholder {
    text-align: center !important;
    font-size: 18px;
    margin-left: 180px !important;
    letter-spacing: 1px !important;
  }
  .step3-inputs >>> .v-input__icon {
    margin-top: -9px;
  }

  .next-button-step2 {
    width: 100% !important;
    height: 60px !important;
  }

  .start-text {
    width: 80px;
    height: 30px;
    margin: 0 16px 0 0;
    font-size: 18px;
    line-height: 1.7;
  }
  .start-text-white {
    width: 80px;
    height: 30px;
    margin: 0 16px 0 0;
    font-size: 18px;
    line-height: 1.7;
  }
}

@media screen and (max-width: 480px) {
}

@media screen and (max-width: 375px) {
  .step3-inputs >>> .v-input__icon {
    margin-top: -9px;
    margin-left: -60px;
  }
}
/* to remove margin left after 600px and 1264px */
@media (min-width: 600px) and (max-width: 1264px) {
  .v-application--is-ltr .offset-sm-3 {
    margin: auto;
  }
}
</style>
