<template>
  <v-app>
     <notifications animation-type="velocity" :animation="animation"/>
    <!-- md lg xl screen snackbar -->

    <v-snackbar
      v-if="
        $vuetify.breakpoint.mdOnly ||
          $vuetify.breakpoint.lgOnly ||
          $vuetify.breakpoint.xlOnly
      "
      :timeout="3000"
      v-model="snackbar"
      class="sncakbar"
    >
      <template>
        <!--community name -->
        <v-app-bar
          app
          color="#ff4e4e"
          height="140"
          v-show="!communityNameValid && !valid"
        >
          <v-col class="d-flex justify-center col-md-12">
            <v-toolbar-title style="font-family : GoogleSans-Regular"
              ><v-icon size="40" center>mdi-alert-circle-outline</v-icon> This
              name is already taken. Try another one.</v-toolbar-title
            >
          </v-col>
        </v-app-bar>
        <!--subdomain -->
        <v-app-bar
          app
          color="#ff994e"
          height="140"
          v-show="!subdomainValid && !valid"
        >
          <v-col class="d-flex text-center col-md-12">
            <v-toolbar-title
              class="d-flex-inline justify-center col-md-12"
              style="font-family : GoogleSans-Regular"
              ><v-icon size="40" center>mdi-alert-circle-outline</v-icon> This
              subdomain is already taken. Try another one.</v-toolbar-title
            >
          </v-col>
        </v-app-bar>
        <!--verification code -->
        <v-app-bar
          app
          color="#ff4e4e"
          height="140"
          v-show="verificationCode && !valid"
        >
          <v-col class="d-flex justify-center col-md-12">
            <v-toolbar-title class="" style="font-family : GoogleSans-Regular"
              ><v-icon size="40" center>mdi-alert-circle-outline</v-icon> Your
              verification code is wrong.</v-toolbar-title
            >
          </v-col>
        </v-app-bar>
      </template>
    </v-snackbar>

    <!-- app bar sm xs screen snackbar -->

    <v-snackbar
      v-if="$vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly"
      :timeout="3000"
      v-model="snackbar"
      class="sncakbar"
    >
      <template>
        <!--community name -->
        <v-app-bar
          app
          color="#ff4e4e"
          class="app-bar_2"
          v-show="!communityNameValid && !valid"
        >
          <v-col class="d-flex justify-center">
            <v-toolbar-title style="font-family : GoogleSans-Regular;"
              ><v-icon size="32" center>mdi-alert-circle-outline</v-icon> This
              name is already taken. Try another one.</v-toolbar-title
            >
          </v-col>
        </v-app-bar>
        <!--subdomain -->
        <v-app-bar
          app
          color="#ff994e"
          class="app-bar_2"
          v-show="!subdomainValid && !valid"
        >
          <v-col class="d-flex justify-center">
            <v-toolbar-title style="font-family : GoogleSans-Regular;"
              ><v-icon size="32" center>mdi-alert-circle-outline</v-icon> This
              subdomain is already taken. <br />
              Try another one.</v-toolbar-title
            >
          </v-col>
        </v-app-bar>
        <!--verification code -->
        <v-app-bar
          app
          color="#ff4e4e"
          class="app-bar_2"
          v-show="verificationCode && !valid"
        >
          <v-col class="d-flex justify-center">
            <v-toolbar-title style="font-family : GoogleSans-Regular;"
              ><v-icon size="32" center>mdi-alert-circle-outline</v-icon> Your
              verification code is wrong.</v-toolbar-title
            >
          </v-col>
        </v-app-bar>
      </template>
    </v-snackbar>

    <!-- app bar sm xs screen style in css -->

    <v-app-bar
      v-if="$vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly"
      app
      color="black"
      dark
      class="logo-xs-center app-bar"
    >
      <div class="d-flex justify-center mx-auto">
        <router-link to="/">
          <v-img
            alt="Octo Logo"
            class="shrink mr-2 logo"
            contain
            src="./assets/octo.png"
            transition="scale-transition"
          />
        </router-link>
      </div>
    </v-app-bar>

    <!-- app bar md lg xl screen element style -->

    <v-app-bar
      v-if="
        (homePage && $vuetify.breakpoint.mdOnly) ||
          $vuetify.breakpoint.lgOnly ||
          $vuetify.breakpoint.xlOnly
      "
      app
      color="black"
      height="140"
      dark
      class="logo-xs-center"
    >
      <div class="d-flex justify-center mx-auto">
        <router-link to="/">
          <v-img
            alt="Octo Logo"
            class="shrink mr-2 logo"
            contain
            src="./assets/octo.png"
            transition="scale-transition"
            height="80"
            width="185"
          />
        </router-link>
      </div>

      <v-spacer></v-spacer>

      <div v-if="homePage" >
        <v-btn
          :disabled="allStepCorrectDisableButton"
          fab
          dark
          small
          class="outlined"
          width="56"
          height="56"
          :class="{ active: step >= 1, 'active-glow': step > 1 }"
          @click="setStep(1)"
        >
          <span
            class="nav-btn-text"
            :class="{ 'nav-btn-text-color': step >= 1 }"
            >1</span
          >
        </v-btn>
        <div class="line"></div>
        <v-btn
          :disabled="allStepCorrectDisableButton"
          fab
          dark
          small
          class="outlined"
          width="56"
          height="56"
          :class="{ active: step >= 2, 'active-glow': step > 2 }"
          @click="
            (step === 1 && valid === true) || step === 3 ? setStep(2) : false
          "
        >
          <span
            class="nav-btn-text"
            :class="{ 'nav-btn-text-color': step >= 2 }"
            >2</span
          >
        </v-btn>
        <div class="line"></div>
        <v-btn
          :disabled="allStepCorrectDisableButton"
          fab
          dark
          small
          class="outlined"
          width="56"
          height="56"
          :class="{ active: step === 3 }"
          @click="
            step === 2 && valid === true && activatedStep3 === true
              ? setStep(3)
              : false
          "
        >
          <span
            class="nav-btn-text"
            :class="{ 'nav-btn-text-color': step === 3 }"
            >3</span
          >
        </v-btn>
      </div>
    </v-app-bar>

    <v-main>
      <transition name="fade" mode="out-in">
        <router-view @step3-activated="activatedStep3 = $event" @allStepCorrectDisableButton="allStepCorrectDisable" />
      </transition>
    </v-main>

    <!-- md lg xl display case v-footer -->
    <v-footer
      padless
      v-if="
        $vuetify.breakpoint.mdOnly ||
          $vuetify.breakpoint.lgOnly ||
          $vuetify.breakpoint.xlOnly
      "
    >
      <v-col class="text-left" cols="2">
        <span style="font-family : GoogleSans-Regular"
          >©{{ new Date().getFullYear() }} — Octo | All rights reserved.</span
        >
      </v-col>
      <v-col cols="4">
        <v-btn
          color="black"
          href="https://www.getocto.com/terms-of-use"
          style="font-family : GoogleSans-Regular;padding:0px;"
          :ripple="false"
          text
        >
          Terms
        </v-btn>
        <v-btn
          color="black"
          href="https://www.getocto.com/privacy"
          style="font-family : GoogleSans-Regular;padding:5px;"
          :ripple="false"
          text
        >
          Privacy
        </v-btn>
      </v-col>
      <v-col class="text-right offset-3" cols="3">
        <v-btn
          color="black"
          href="https://www.getocto.com/about-octo"
          style="font-family : GoogleSans-Regular;padding:5px;"
          :ripple="true"
          text
        >
          About
        </v-btn>
        <v-btn
          color="black"
          href="https://www.getocto.com/contact"
          style="padding:0px;"
          :ripple="true"
          text
        >
          Contact
        </v-btn>
      </v-col>
    </v-footer>

    <!-- xs sm display case v-footer -->

    <v-footer
      padless
      v-if="$vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly"
    >
      <v-col cols="12">
        <div class="d-flex flex-row justify-center">
          <v-btn
            class="pa-md-3 pa-xs-0"
            color="black"
            href="https://www.getocto.com/about-octo"
            style="font-family : GoogleSans-Regular;padding:0px;color: #243137 !important;font-size: 16px"
            :ripple="false"
            text
          >
            About
          </v-btn>
          <v-btn
            class="pa-md-3 pa-xs-0"
            color="black"
            href="https://www.getocto.com/contact"
            style="font-family : GoogleSans-Regular;padding:5px;color: #243137 !important;font-size: 16px"
            :ripple="false"
            text
          >
            Contact
          </v-btn>
          <v-btn
            class="pa-md-3 pa-xs-0"
            color="black"
            href="https://www.getocto.com/terms-of-use"
            style="font-family : GoogleSans-Regular;padding:5px;color: #243137 !important;font-size: 16px"
            :ripple="true"
            text
          >
            Terms
          </v-btn>
          <v-btn
            class="pa-md-3 pa-xs-0"
            color="black"
            href="https://www.getocto.com/privacy"
            style="font-family : GoogleSans-Regular;padding:0px; color: #243137 !important;font-size: 16px"
            :ripple="true"
            text
          >
            Privacy
          </v-btn>
        </div>
      </v-col>
      <v-col class="text-center">
        <div class="d-flex flex-row justify-center">
          <span
            style="font-family : GoogleSans-Regular;color: #77909b !important;font-size: 12px"
            >©{{ new Date().getFullYear() }} — Octo | All rights reserved.</span
          >
        </div>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "App",

  data: () => ({
    activatedStep3: false,
    allStepCorrectDisableButton: false
  }),
  computed: {
    animation () {
      return {
        /**
         * Animation function
         * 
         * Runs before animating, so you can take the initial height, width, color, etc
         * @param  {HTMLElement}  element  The notification element
         */
        enter (element) {
          let height = element.clientHeight
          return {
            // animates from 0px to "height"
            height: [height, 0],

            // animates from 0 to random opacity (in range between 0.5 and 1)
            opacity: [Math.random() * 0.5 + 0.5, 0]
          }
        },
        leave: {
          height: 0,
          opacity: 0
        }
      }
    },
    ...mapState({
      step: state => state.step,
      verificationCode: state => state.verificationCode,
      verificationCodePhoneCode: state => state.verificationCodePhoneCode
    }),
    homePage() {
      return this.$route.path == "/";
    },
    valid: {
      get() {
        return this.$store.state.valid;
      },
      set(value) {
        this.$store.commit("setValid", value);
      }
    },
    communityNameValid: {
      get() {
        return this.$store.state.communityNameValid;
      }
    },
    subdomainValid: {
      get() {
        return this.$store.state.subdomainValid;
      }
    },
    snackbar: {
      get() {
        return this.$store.state.snackbar;
      },
      set(value) {
        this.$store.commit("activetedSnackbar", value);
      }
    }
  },
  methods: {
    ...mapActions(["setStep"]),
    allStepCorrectDisable (e) {
      this.allStepCorrectDisableButton = e
    }
  }
};
</script>

<style scoped>
.v-btn {
  text-transform: none !important;
}

@font-face {
  font-family: "GoogleSans-Regular";
  src: local("GoogleSans-Regular"),
    url(./assets/fonts/GoogleSans-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "GoogleSans-Bold";
  src: local("GoogleSans-Bold"),
    url(./assets/fonts/GoogleSans-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "GoogleSans-Medium";
  src: local("GoogleSans-Medium"),
    url(./assets/fonts/GoogleSans-Medium.ttf) format("truetype");
}

.v-main {
  background-color: #f7f7f7;
}
.v-btn.outlined {
  border: 2px solid white;
}
.v-btn::before {
  background-color: transparent !important;
}
.v-btn:hover::before {
  background-color: white;
}

.nav-btn-text {
  height: 36px;
  font-family: GoogleSans-Regular;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: rgba(176, 189, 196, 0.6);
}
.nav-btn-text-color {
  color: #1bacc0;
}

.line {
  display: inline-block;
  width: 48px;
  height: 0;
  margin: 28px 0 0;
  border: solid 2px rgba(176, 189, 196, 0.6);
}

.active {
  background-color: white !important;
}
.active-glow {
  box-shadow: 0 0 20px 0 rgba(23, 255, 246, 0.9) !important;
}
/* .app-bar_2 {
  display: flex !important;
} */
.already-taken-text {
  width: 469px;
  height: 30px;
  margin: 5px 0 5px 16px;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}
.sncakbar >>> .v-snack__wrapper {
  max-width: 0px !important;
  min-height: 0px !important;
  min-width: 0px !important;
}
.sncakbar >>> .v-snack__content {
  padding: 0px 0px !important;
}

@media (min-width: 1025px) {
  .app-bar {
    height: 140px !important;
  }
  .app-bar_2 {
    height: 140px !important;
    justify-content: center;
    align-items: center;
  }
  .logo {
    height: 80px !important;
    width: 185px !important;
  }
}
@media (min-width: 960px) and (max-width: 1024px) {
  .app-bar {
    height: 13% !important;
  }
  .app-bar_2 {
    height: 140px !important;
    justify-content: center;
    align-items: center;
  }
  .logo {
    margin-top: 0% !important;
    height: 70px !important;
    width: 150px !important;
  }
}

@media (min-width: 768px) and (max-width: 959px) {
  .app-bar {
    height: 80px !important;
    margin-bottom: 140px;
  }
  .app-bar_2 {
    height: 140px !important;
    justify-content: center;
    align-items: center;
  }
  .logo {
    margin-top: 25% !important;
    width: 100px !important;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .app-bar {
    height: 80px !important;
  }
  .app-bar_2 {
    height: 80px !important;
    justify-content: center;
    align-items: center;
  }

  .logo {
    margin-top: 25% !important;
    width: 100px !important;
  }
}

@media screen and (max-width: 480px) {
  .app-bar {
    height: 80px !important;
  }
  .app-bar_2 {
    height: 80px !important;
    justify-content: center;
    align-items: center;
  }

  .logo {
    height: 40px !important;
    width: 92px !important;
    margin-top: 20% !important;
  }
}

@media (min-width: 600px) and (max-width: 960px) {
  .sncakbar >>> .v-toolbar__content {
    padding: 4px 0px !important;
  }
}

@media (max-width: 600px) {
  /* toolbar logo xs aling center set */

  .logo-xs-center >>> .v-toolbar__content {
    padding: 4px 100px !important;
  }
  .sncakbar >>> .v-toolbar__content {
    padding: 4px 0px !important;
  }
  .sncakbar >>> .v-toolbar__title {
    font-size: 14px !important;
  }
}

/* 1920px desktop max-width settings */
@media screen and (min-width: 1920px) {
  .container {
    max-width: 1185px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
