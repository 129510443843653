import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";

import axios from "axios";
import VueAxios from "vue-axios";

import AhtapotJS from '@ahtapotapp/ahtapot-js-sdk'
import AhtapotJSGetStarted from '@ahtapotapp/ahtapot-js-sdk-get-started';

import Notifications from 'vue-notification'
import velocity      from 'velocity-animate'
Vue.use(Notifications, { velocity })

window.$getStarted = new AhtapotJSGetStarted({});
Vue.use(VueAxios, axios);


Vue.use(VueTelInputVuetify, {
  vuetify
});
Vue.config.productionTip = false;

Vue.prototype.$api = Vue.axios.create({
  baseURL: "https://api.ahtapotapp.com",
  headers: {
    "X-Ahtapot-Company": "MTEz",
    "X-Ahtapot-Timezone": "Europe/Istanbul"
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
