import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    step: 1,
    valid: true,
    validPhoneCode: true,
    communityNameValid: null,
    subdomainValid: null,
    snackbar: false,
    verificationCode: false,
    verificationCodePhoneCode: false
  },
  getters: {
    getCommunityNameValid(state) {
      return state.communityNameValid;
    },
    getSubdomainValid(state) {
      return state.subdomainValid;
    }
  },
  mutations: {
    incrementStep(state) {
      state.step++;
    },
    setStep(state, payload) {
      state.step = payload;
    },
    setValid(state, payload) {
      state.valid = payload;
    },
    setValidPhoneCode(state, payload) {
      state.validPhoneCode = payload;
    },
    setCommunityNameValid(state, payload) {
      state.communityNameValid = payload;
    },
    setSubdomainValid(state, payload) {
      state.subdomainValid = payload;
    },
    activetedSnackbar(state, payload) {
      state.snackbar = payload;
    },
    setVerificationCode(state, payload) {
      state.verificationCode = payload;
    },
    setVerificationCodePhoneCode(state, payload) {
      state.verificationCodePhoneCode = payload;
    }
  },
  actions: {
    incrementStep(context) {
      context.commit("incrementStep");
    },
    setStep(context, payload) {
      context.commit("setStep", payload);
    },
    checkCommunity(context, payload) {
      // request for community name

      this._vm.$api
        .post("/company_registration", {
          company_registration: {
            name: payload.communityName,
            domain: payload.subdomain
          }
        })
        .then(function (resp) {
          context.commit("setCommunityNameValid", true);
          context.commit("setSubdomainValid", true);

          context.commit("setValid", true);
          context.commit("activetedSnackbar", false);
        })
        .catch(function (err) {
          context.commit("setCommunityNameValid", false);
          context.commit("setSubdomainValid", false);

          context.commit("setValid", false);
          context.commit("activetedSnackbar", true);
        });

      // if (
      //   context.getters.getCommunityNameValid &&
      //   context.getters.getSubdomainValid
      // ) {
      //   console.log("name:", context.getters.getCommunityNameValid)
      //   console.log("domain:", context.getters.getSubdomainValid)
      //   context.commit("setValid", false);
      //   context.commit("activetedSnackbar", true);
      // } else {
      //   console.log("name2:", context.getters.getCommunityNameValid)
      //   console.log("domain2:", context.getters.getSubdomainValid)
      //   context.commit("setValid", true);
      //   context.commit("activetedSnackbar", false);
      // }
    },
    createCommunity(context, payload) {
      const self = this;
      console.log(payload);
      let phone = payload.account.phone
        .replace(/^0+/, "")
        .replace(/ /g, "")
        .replace(/-/g, "")
        .replace(/\(/g, "")
        .replace(/\)/g, "")
        // .replace(/\-/g, "");

      phone = "+" + payload.account.dialCode + phone;
      console.log(phone);
      return new Promise(function (resolve, reject) {
        self._vm.$api
          .post("/company_registration?step=two", {
            company_registration: {
              name: payload.name,
              domain: payload.subdomain,
              segment_id: 1,
              first_name: payload.account.firstname,
              last_name: payload.account.lastname,
              email: payload.account.email,
              phone: phone,
              newsletter: payload.newsletter,
              hcaptcha: payload.hcaptcha
            }
          })
          .then(function (response) {
            console.log("company_create_response: ", response);
            resolve();
          })
          .catch(function (err) {
            console.log("company_create_err_response: ", err);
            reject();
          });
      });
    },
    checkVerificationCode(context, code) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self._vm.$api
          .post("/company_registration?step=three", {
            company_registration: {
              code: code
            }
          })
          .then(function (response) {
            console.log("step three company_create_response: ", response);
            context.commit("setVerificationCode", false);
            context.commit("setValid", true);
            context.commit("activetedSnackbar", false);
            resolve();
          })
          .catch(function (err) {
            console.log("step three company_create_err_response: ", err);
            context.commit("setVerificationCode", true);
            context.commit("setValid", false);
            context.commit("activetedSnackbar", true);
            reject();
          });
      });
      /*if (code == "1234") {
        context.commit("setVerificationCode", false);
        context.commit("setValid", true);
        context.commit("activetedSnackbar", false);
      } else {
        context.commit("setVerificationCode", true);
        context.commit("setValid", false);
        context.commit("activetedSnackbar", true);
      }*/
    },
    checkVerificationCodePhoneCode(context, code) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self._vm.$api
          .post("/company_registration?step=four", {
            company_registration: {
              code: code
            }
          })
          .then(function (response) {
            console.log("step four company_create_response: ", response);
            context.commit("setVerificationCodePhoneCode", false);
            context.commit("setValidPhoneCode", true);
            context.commit("activetedSnackbar", false);
            resolve(response);
          })
          .catch(function (err) {
            console.log("step four company_create_err_response: ", err);
            context.commit("setVerificationCodePhoneCode", true);
            context.commit("setValidPhoneCode", false);
            context.commit("activetedSnackbar", true);
            reject();
          });
      });
    }
  },
  modules: {}
});